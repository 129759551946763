
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import type { TextArray } from '@/definitions/shared/types'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    TmStylizedText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
      required: true,
    },
    textContainer: {
      type: String,
      default: 'span',
    },
    btnText: {
      type: String,
      required: true,
    },
  },
  setup() {
    const state = ref('keep')

    return {
      state,
    }
  },
})
